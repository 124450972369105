import React, { lazy, Suspense, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { SparklesCore } from './components/ui/sparkles';
import ShootingStars from './components/ui/ShootingStars';
import './App.css';

// Lazy-loaded components for better performance
const PaintingsGallery = lazy(() => import('./PaintingsGallery'));
const PreviewGenerator = lazy(() => import('./components/PreviewGenerator'));

// Project data moved into a separate constant
const PROJECT_DATA = [
  {
    id: 'findash',
    title: "FinDash",
    description: "Your Financial Dashboard. Know it to grow. See all your finances clearly. Make smarter decisions. Collaborate effortlessly.",
    role: "CoFounder & Developer",
    url: "https://findash.ai"
  },
  {
    id: 'supertutor',
    title: "Supertutor",
    description: "FASTER than ChatGPT...More CREATIVE than Kahoot...SMARTER than your human tutor!",
    role: "Founder & Developer",
    url: "https://supertutorai.co"
  },
  {
    id: 'tutto',
    title: "tuttO Game",
    description: "tuttO is the spicy new party game where laughter leads to love...or at least some unforgettable fun",
    role: "Cofounder & Developer",
    url: "https://tuttoplay.com/"
  },
  {
    id: 'bigsignal',
    title: "Big Signal",
    description: "IOS personal safety app emergency alerts to trusted contacts with location tracking",
    role: "Cofounder & Developer",
    url: "https://apps.apple.com/kg/app/big-signal-personal-safety/id6472224823"
  }
];

// Experience data moved into a separate constant
const EXPERIENCE_DATA = [
  {
    id: 'tesla',
    company: "Tesla",
    role: "Lead Autopilot Data Specialist",
    duration: "2021",
    description: "Led data analysis initiatives for autonomous driving systems"
  },
  {
    id: 'northwell',
    company: "Northwell Health",
    duration: "2020 — 2021",
    description: "Developed interactive art installations for healthcare exhibitions"
  },
  {
    id: 'art',
    company: "Art Portfolio",
    duration: "2016 — Present",
    description: "Create paintings, illustrations, animation & short films using Procreate and traditional media.",
    linkTo: "/paintings",
    linkText: "View Portfolio"
  }
];

// Social links data moved into a separate constant
const SOCIAL_LINKS = [
  {
    name: "GitHub", 
    url: "https://github.com/adamjonah", 
    ariaLabel: "Visit Adam Jonah's GitHub profile"
  },
  {
    name: "LinkedIn", 
    url: "https://linkedin.com/in/adamjonah", 
    ariaLabel: "Visit Adam Jonah's LinkedIn profile"
  },
  {
    name: "X", 
    url: "https://x.com/adamjonah_", 
    ariaLabel: "Visit Adam Jonah's X profile"
  },
  {
    name: "Instagram", 
    url: "https://instagram.com/adamjonah_", 
    ariaLabel: "Visit Adam Jonah's Instagram profile"
  },
  {
    name: "Email", 
    url: "mailto:adamjonahstudio@gmail.com", 
    ariaLabel: "Email Adam Jonah"
  }
];

// ProjectCard component moved out for clarity
const ProjectCard = ({ project }) => {
  const handleClick = useCallback(() => {
    window.open(project.url, '_blank', 'noopener,noreferrer');
  }, [project.url]);

  const handleLinkClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <motion.div 
      className="experience-item project-card"
      onClick={handleClick}
      whileHover={{ y: -8, backgroundColor: 'rgba(255, 255, 255, 0.05)', borderColor: 'rgba(255, 255, 255, 0.2)' }}
      transition={{ type: "spring", stiffness: 300, damping: 20 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      style={{ cursor: 'pointer' }}
    >
      <div className="project-content">
        <div className="experience-header">
          <motion.h3 
            className="experience-title"
            whileHover={{ scale: 1.02 }}
          >
            {project.title}
          </motion.h3>
        </div>
        <p className="experience-role">{project.role}</p>
        <p className="experience-description">{project.description}</p>
        <motion.a 
          href={project.url} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="portfolio-link"
          onClick={handleLinkClick}
          whileHover={{ x: 4, backgroundColor: 'rgba(255, 255, 255, 0.15)' }}
        >
          View <motion.span className="arrow" whileHover={{ x: 2 }}>↗</motion.span>
        </motion.a>
      </div>
    </motion.div>
  );
};

// ExperienceItem component moved out for clarity
const ExperienceItem = ({ experience, children }) => (
  <motion.div 
    className="experience-item"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    whileHover={{ y: -2, boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}
  >
    <div className="experience-header">
      <h3 className="experience-title">{experience.company}</h3>
      <span className="duration">{experience.duration}</span>
    </div>
    {experience.role && <p className="experience-role">{experience.role}</p>}
    <p className="experience-description">{experience.description}</p>
    {children && <div className="experience-actions">{children}</div>}
  </motion.div>
);

// HomePage component moved out for clarity
const HomePage = () => {
  return (
    <div className="main-content">
      <div className="sparkles-container">
        <SparklesCore
          id="tsparticlescolorful"
          background="transparent"
          minSize={0.4}
          maxSize={2}
          particleDensity={200}
          className="w-full h-full"
          particleColor="#FFFFFF"
          speed={0.2}
        />
        <ShootingStars count={15} minDuration={2.5} maxDuration={4.5} />
      </div>
      
      <motion.section 
        className="subtitle-section"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      >
        <p className="subtitle">Creative Technologist</p>
      </motion.section>

      <motion.section 
        className="projects-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        <div className="section-header">
          <span className="section-label">/ PROJECTS</span>
        </div>
        <div className="experience-list">
          {PROJECT_DATA.map((project, index) => (
            <ProjectCard 
              key={project.id} 
              project={project} 
            />
          ))}
        </div>
      </motion.section>

      <motion.section 
        className="experience-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4 }}
      >
        <div className="section-header">
          <span className="section-label">/ EXPERIENCE</span>
        </div>
        <div className="experience-list">
          {EXPERIENCE_DATA.map((exp, index) => (
            <ExperienceItem key={exp.id} experience={exp}>
              {exp.linkTo && (
                <Link to={exp.linkTo} className="portfolio-link">
                  {exp.linkText} <span className="arrow">↗</span>
                </Link>
              )}
            </ExperienceItem>
          ))}
        </div>
      </motion.section>
    </div>
  );
};

// Header component moved out for clarity
const Header = () => (
  <header className="header">
    <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
      <motion.h1
        className="studio-name"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        whileHover={{ scale: 1.03 }}
      >
        Adam Jonah Studio
      </motion.h1>
    </Link>
  </header>
);

// Footer component moved out for clarity
const Footer = () => (
  <footer className="footer">
    <div className="footer-content">
      <div className="section-header">
        <span className="section-label">/ CONNECT</span>
      </div>
      <div className="contact-links">
        {SOCIAL_LINKS.map((link, index) => (
          <motion.a 
            key={link.name}
            href={link.url} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="contact-link"
            aria-label={link.ariaLabel}
            whileHover={{ x: 4 }}
            initial={{ opacity: 0, x: -5 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.1 * index }}
          >
            {link.name} <span className="arrow">↗</span>
          </motion.a>
        ))}
      </div>
    </div>
    <p className="copyright"> {new Date().getFullYear()} Adam Jonah Studio</p>
  </footer>
);

// Loading component for Suspense
const Loading = () => (
  <div style={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100vh',
    color: 'var(--text-primary)'
  }}>
    <motion.div
      animate={{ 
        opacity: [0.3, 1, 0.3],
        scale: [0.95, 1, 0.95]
      }}
      transition={{ 
        repeat: Infinity, 
        duration: 1.5 
      }}
    >
      Loading...
    </motion.div>
  </div>
);

// Main App component
function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <AnimatePresence mode="wait">
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/paintings" element={<PaintingsGallery />} />
                <Route path="/generate-preview" element={<PreviewGenerator />} />
              </Routes>
            </Suspense>
          </AnimatePresence>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;