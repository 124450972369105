import React, { useEffect, useRef } from 'react';
import './ShootingStars.css';

const ShootingStars = ({ count = 12, minDuration = 2, maxDuration = 5 }) => {
  const containerRef = useRef(null);
  
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    
    // Clear any existing stars
    container.innerHTML = '';
    
    // Create a few initial stars
    for (let i = 0; i < 3; i++) {
      setTimeout(() => {
        if (container) createShootingStar(container, minDuration, maxDuration);
      }, i * 1200);
    }
    
    // Set up interval to create new stars with natural timing
    let lastCreationTime = Date.now();
    
    const interval = setInterval(() => {
      const now = Date.now();
      const timeSinceLastCreation = now - lastCreationTime;
      
      // Create stars with natural timing and pauses
      // Higher chance of creating a star if it's been a while
      const randomFactor = Math.min(0.95, timeSinceLastCreation / 5000);
      
      if (container.children.length < count && Math.random() < randomFactor) {
        createShootingStar(container, minDuration, maxDuration);
        lastCreationTime = now;
      }
    }, 800);
    
    return () => clearInterval(interval);
  }, [count, minDuration, maxDuration]);
  
  // Function to create a premium quality shooting star
  const createShootingStar = (container, minDuration, maxDuration) => {
    // Create star container for better positioning and animation
    const starContainer = document.createElement('div');
    starContainer.className = 'star-container';
    
    // Create the actual star with trail
    const star = document.createElement('div');
    star.className = 'shooting-star';
    
    // Create the star head (bright point)
    const starHead = document.createElement('div');
    starHead.className = 'star-head';
    
    // Create the star trail
    const starTrail = document.createElement('div');
    starTrail.className = 'star-trail';
    
    // Random position with better distribution
    // Favor starting from top-left quadrant for more natural feel
    const startX = Math.random() * 40; // 0-40% of screen width
    const startY = Math.random() * 30; // 0-30% of screen height
    
    // Randomize angle with natural downward trajectory
    // Most meteors fall at similar angles in reality
    const baseAngle = -50; // Base angle pointing down-right
    const angleVariation = 15; // Small variation for natural feel
    const angle = baseAngle + (Math.random() * angleVariation - angleVariation/2);
    
    // Premium quality size and duration
    const headSize = Math.random() * 1.5 + 1; // 1-2.5px
    const trailLength = Math.random() * 35 + 25; // 25-60px
    
    // Eased duration for more natural movement
    // Shorter stars move a bit faster (inverse relationship)
    const sizeFactor = 1 - (headSize - 1) / 1.5; // 0-1 based on size
    const baseDuration = minDuration + (maxDuration - minDuration) * sizeFactor;
    const duration = baseDuration + Math.random() * 1; // Add small random factor
    
    // Natural delay between stars
    const delay = Math.random() * 2; // 0-2s delay
    
    // Brightness based on size (larger stars appear brighter)
    const brightness = 0.7 + (headSize - 1) / 1.5 * 0.3; // 0.7-1.0
    
    // Apply styles to star container
    starContainer.style.left = `${startX}%`;
    starContainer.style.top = `${startY}%`;
    starContainer.style.transform = `rotate(${angle}deg)`;
    
    // Apply styles to star head
    starHead.style.width = `${headSize}px`;
    starHead.style.height = `${headSize}px`;
    starHead.style.opacity = brightness;
    
    // Apply styles to star trail
    starTrail.style.height = `${trailLength}px`;
    starTrail.style.opacity = brightness * 0.9;
    
    // Apply animation styles
    starContainer.style.animationDuration = `${duration}s`;
    starContainer.style.animationDelay = `${delay}s`;
    
    // Determine if this should be a premium star (brighter, more dramatic)
    if (Math.random() > 0.7) {
      starContainer.classList.add('premium-star');
      starHead.classList.add('premium-head');
      starTrail.classList.add('premium-trail');
    }
    
    // Assemble the star
    star.appendChild(starHead);
    star.appendChild(starTrail);
    starContainer.appendChild(star);
    container.appendChild(starContainer);
    
    // Remove after animation completes with buffer
    setTimeout(() => {
      if (container.contains(starContainer)) {
        // Fade out gracefully
        starContainer.classList.add('fade-out');
        
        // Then remove from DOM
        setTimeout(() => {
          if (container.contains(starContainer)) {
            container.removeChild(starContainer);
          }
        }, 300);
      }
    }, (duration + delay) * 1000);
  };
  
  return (
    <div className="shooting-stars-container" ref={containerRef}></div>
  );
};

export default ShootingStars;
